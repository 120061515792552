export default {
  siteTitle: 'Get bonus',
  blogerName: 'VITAO',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Vitaosemthumbs/videos'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@vitaothumbs'
    },
    {
      name: 'instagram',
      url: 'http://instagram.com/vitaothumbs'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/c50a0fb8c',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irit01.com/c6a74c2f7',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c63b4b1e2',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'starda',
      url: 'https://strd-irit01.com/cc9f668b5',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c78e56e3e',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir13.com/c99baaf88',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c07ee062e',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c4e108ba6',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jtcaz-intfr02.com/ceb3ed9ff',
      gameTitle: 'Jet Air',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>VITAO</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'VITAO',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
